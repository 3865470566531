import React from "react"

import {Card, CardBody, Container} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TamashaButtonLink from "../../components/TamashaButtonLink";
import ProjectsTable from "../../components/Tables/ProjectsTable";

const Projects = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title="Projects"/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Projects" {...props}/>
                    <Card>
                        <CardBody>

                            <div className="text-end mb-4">
                                <TamashaButtonLink to={"/dashboard/new-project"}><i
                                    className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}Create a project
                                </TamashaButtonLink>
                            </div>

                            <ProjectsTable/>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Projects
