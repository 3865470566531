const STAGING_URL = "https://api-staging.ticketswithease.com"
const PRODUCTION_URL = "https://api.ticketswithease.com"
const DEVELOPMENT_URL = "http://localhost:9090"

const FRONTEND_STAGING_URL = "https://dev.ticketswithease.com"
const FRONTEND_PRODUCTION_URL = "https://ticketswithease.com"
const FRONTEND_DEVELOPMENT_URL = "http://localhost:3001"

export const isStagingEnvironment = () => window.location.host.includes("develop")

export const isLocalEnvironment = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

export default {
    SERVER_HOST: isLocalEnvironment() ? DEVELOPMENT_URL : isStagingEnvironment() ? STAGING_URL : PRODUCTION_URL,
    FRONTEND_HOST: isLocalEnvironment() ? FRONTEND_DEVELOPMENT_URL : isStagingEnvironment() ? FRONTEND_STAGING_URL : FRONTEND_PRODUCTION_URL,
    API_URL: '/api/v1/',
    FILE_URL: '/files/',

    LOGIN: 'admin/login',
    LOGOUT: 'logout',
    REFRESH_TOKEN: 'refresh',
    ME: 'me',
    PERMISSIONS: 'permissions',
    CHANGE_PASSWORD: 'admin/update/password',
    REQUEST_RESET_CODE: 'password-reset/request-code',
    VALIDATE_RESET_CODE: 'password-reset/validate-code',
    UPDATE_PASSWORD_RESET: 'password-reset/update-password',
    COUNTRIES: (page, query, records) => `countries?page=${page}&s=${query}&records=${records}`,
    TOWN: 'towns',

    ESTATES: (page, query, records) => `estates?page=${page}&s=${query}&records=${records}`,
    ESTATE: `estates`,
    MY_ESTATE: `my-estate`,

    ESTATE_ADMIN: `estate-admin`,

    UNITS: (estateId, page, query, records) => `units?page=${page}&s=${query}&records=${records}&estate_id=${estateId}`,
    UNIT: `units`,

    COMPANIES: (offset, query, records) => `company?offset=${offset}&query=${query}&limit=${records}`,
    COMPANY_WITH_ID: id => `company/${id}`,
    COMPANY: `company`,
    UPDATE_COMPANY_DETAILS: 'company/update/details',
    UPDATE_COMPANY_PHOTO: 'company/update/photo',
    UPDATE_COMPANY_COVER: 'company/update/cover',

    PROJECTS: (offset, query, records, project_owner_id, category, groupId) => `project?offset=${offset}&query=${query}&limit=${records}
    &project_owner_id=${project_owner_id}&category_id=${category}&group_id=${groupId}`,
    PROJECT_WITH_ID: id => `project/${id}`,
    PROJECT: `project`,
    UPDATE_PROJECT_DETAILS: 'project/update/details',
    UPDATE_PROJECT_PHOTO: 'project/update/photo',
    UPDATE_PROJECT_STATUS: 'project/update/status',

    PORTFOLIOS: (offset, query, records, project_owner_id, project_id, user_id) => `portfolio/list-admin?offset=${offset}&query=${query}
    &limit=${records}&owner_id=${project_owner_id}&project_id=${project_id}&user_id=${user_id}`,
    PORTFOLIO_WITH_ID: id => `portfolio/${id}`,
    PORTFOLIO: `portfolio`,

    CATEGORIES: (groupID, offset, query, records) => `category?offset=${offset}&query=${query}&limit=${records}&group_id=${groupID}`,
    CATEGORY_WITH_ID: id => `category/${id}`,
    CATEGORY: `category`,
    UPDATE_CATEGORY_DETAILS: 'category/update/details',

    ACCOUNTS: (offset, query, records) => `user/list?offset=${offset}&query=${query}&limit=${records}`,
    ACCOUNT_WITH_ID: id => `user/${id}`,
    UPDATE_ACCOUNT_STATUS: 'user/update/status',

    DASHBOARD: `admin/dashboard`,

    LIST_ROADMAP: (id, year, quarter) => `roadmap/list/${id}?year=${year}&quarter=${quarter}`,
    CREATE_ROADMAP: `roadmap/create`,
    UPDATE_ROADMAP: `roadmap/update`,
    DELETE_ROADMAP: `roadmap/delete`,
    LIST_PROJECT_UPDATES: (id) => `post/list/${id}`,
    POST_PROJECT_UPDATE: `post/create`,
    UPDATE_PROJECT_UPDATE: `post/update`,
    DELETE_PROJECT_UPDATE: `post/delete`,

    QUESTS: (offset, query, records, quest_owner_id, category) => `quest?offset=${offset}&query=${query}&limit=${records}
    &quest_owner_id=${quest_owner_id}&category_id=${category}`,
    QUEST_WITH_ID: id => `quest/${id}`,
    QUEST: `quest`,
    UPDATE_QUEST_DETAILS: 'quest/update/details',
    UPDATE_QUEST_PHOTO: 'quest/update/photo',
    UPDATE_QUEST_STATUS: 'quest/update/status',

    SKILLS: (offset, query, records, quest_id, category) => `skill?offset=${offset}&query=${query}&limit=${records}
    &quest_id=${quest_id}&category_id=${category}`,
    SKILL_WITH_ID: id => `skill/${id}`,
    SKILL: `skill`,
    UPDATE_SKILL_DETAILS: 'skill/update/details',
    UPDATE_SKILL_PHOTO: 'skill/update/photo',
    UPDATE_SKILL_STATUS: 'skill/update/status',


    GROUPS: (offset, query, records) => `group?offset=${offset}&query=${query}&limit=${records}`,
    GROUP_WITH_ID: id => `group/${id}`,
    GROUP: `group`,
    UPDATE_GROUP_DETAILS: 'group/update/details',

    LIST_TRANSACTIONS: (offset, query, records, project_id) => `transaction/list?offset=${offset}&query=${query}&limit=${records}&project_id=${project_id}`,
}
