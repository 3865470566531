import React from "react";
import {getFileUrl} from "../../../util/Server";
import {Link} from "react-router-dom";
import {getFormarttedNumber} from "../../../util/Util";
import DetailsComponent from "../../../components/DetailsComponent";

export const DetailsTab = ({project}) => {

    return (
        <DetailsComponent
            details={[
                {
                    title: "",
                    des: <img src={getFileUrl(project?.cover_photo)} height={300}/>
                },
                {
                    title: "Project Name",
                    des: project?.name
                },
                 {
                    title: "Project Region",
                    des: project?.region
                },
                {
                    title: "Project Owner",
                    des: <Link
                        to={{
                            pathname: "/dashboard/companies/" + project.project_owner_id,
                            state: {company: project.project_owner}
                        }}>
                        {project.project_owner.name}
                    </Link>
                },
                {
                    title: "Category",
                    des: <Link
                        to={{
                            pathname: "/dashboard/categories/" + project.category_id,
                            state: {category: project.category}
                        }}>
                        {project.category.name}
                    </Link>
                },
                {
                    title: "Description",
                    des: project?.description
                },
                {
                    title: "Verification Status",
                    des: project?.verified ? "VERIFIED" : "PENDING VERIFICATION"
                },
                {
                    title: "Target Amount (EUR)",
                    des: getFormarttedNumber(project?.target_amount)
                },
                {
                    title: "Amount Pledged (EUR)",
                    des: getFormarttedNumber(project?.amount_raised)
                },
                {
                    title: "Investors",
                    des: project?.investors
                },
                {
                    title: "Deadline",
                    des: project?.project_deadline_string
                },
                {
                    title: "Type of Investment",
                    des: project?.type_of_investment
                },
                {
                    title: "Valuation (EUR)",
                    des: getFormarttedNumber(project?.valuation)
                },
                {
                    title: "Total Equity Offered",
                    des: project?.total_equity_offered
                },
                {
                    title: "Share Price",
                    des: project?.share_price
                },
                {
                    title: "Tax Relief",
                    des: project?.tax_relief
                },
                {
                    title: "Created At",
                    des: project?.created_at_string
                },
            ]}
        />
    )
}
