import React, {useEffect} from "react"

import {Card, CardBody, Col, Container, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TextField from "../../components/TextField"
import ProgressView from "../../components/ProgressView"
import FormContainer from "../../components/FormContainer";
import TamashaButton from "../../components/TamashaButton";
import {FormAlert} from "../../components/FormAlert";
import {useCompanies} from "../../hooks/useCompanies";
import {useProjects} from "../../hooks/useProjects";
import SelectView from "../../components/SelectView";
import DatePicker from "../../components/DatePicker";
import {useCategories} from "../../hooks/useCategories";
import {useGroups} from "../../hooks/useGroups";

const NewProject = props => {

    const {
        name,
        setName,
        setCreateAnother,
        createAnother,
        loading,
        error,
        message,
        loadingStates,
        setPhoto,
        createProject,
        deadline,
        setDeadline,
        description,
        setDescription,
        totalEquity,
        setTotalEquity,
        valuation,
        setValuation,
        sharePrice,
        setSharePrice,
        targetAmount,
        setTargetAmount,
        taxRelief,
        setTaxRelief,
        typeOfInvestment,
        setTypeOfInvestment,
        category,
        setCategory,
        ownerID,
        setOwnerID,
        videoUrl,
        setVideoUrl,
        region,
        setRegion,
        riskLevel,
        setRiskLevel,
        group,
        setGroup
    } = useProjects()

    const {companies, loadCompanies} = useCompanies()
    const {
        categories,
        loadCategories,
        loading: loadingCategories,
        loadingStates: loadingStatesCategories
    } = useCategories()
    const {loadGroups, groups} = useGroups()

    useEffect(() => {
        loadCompanies(0, '', 0)
        loadGroups(0, '')
    }, [])

    useEffect(() => {
        if (!group) return

        loadCategories(group, 0, '')
    }, [group])

    useEffect(() => {
        groups.length > 0 && setGroup(groups[0].id)
    }, [groups])

    useEffect(() => {
        if (categories.length === 0) {
            setCategory('');
            return
        }

        setCategory(categories[0].id)
    }, [categories])

    useEffect(() => {
        companies.length > 0 && setOwnerID(companies[0].id)
    }, [companies])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={"Create a project"}/>
                <Container fluid={true}>
                    <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Create a project"} {...props}/>

                    <FormContainer>
                        <Card>
                            <CardBody>
                                <h4 className="card-title mb-4">Fill in the form below to add a new project</h4>

                                <FormAlert message={message} error={error}/>
                                <Row className={"mt-0"}>
                                    <Col md={12}>
                                        <SelectView
                                            onChange={e => setRegion(e.target.value)}
                                            value={region}
                                            label={"Select region."}
                                        >
                                            <option value={"Europe"}>Europe</option>
                                            <option value={"Malaysia"}>Malaysia</option>
                                        </SelectView>
                                        <p>Europe - Projects in this region will be paid by Stripe method with an INVEST
                                            button</p>
                                        <p>Malaysia - Projects in this region will be paid by Curlec method with a
                                            DONATE button</p>
                                    </Col>
                                    <Col md={12}>
                                        <TextField
                                            label={"Name"}
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <label>Cover photo</label>
                                        <TextField
                                            type={'file'}
                                            label={""}
                                            onChange={e => setPhoto(e.target.files[0])}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <SelectView
                                            onChange={e => setOwnerID(e.target.value)}
                                            value={ownerID}
                                            label={"Select company"}
                                        >
                                            {companies?.map(unit => <option
                                                value={unit.id}>{unit.name}</option>)}
                                        </SelectView>
                                    </Col>
                                    <Col md={6}>
                                        <SelectView
                                            onChange={e => setGroup(e.target.value)}
                                            value={group}
                                            label={"Select category group"}
                                        >
                                            {groups?.map(unit => <option
                                                value={unit.id}>{unit.name}</option>)}
                                        </SelectView>
                                    </Col>
                                    <Col md={6}>
                                        <SelectView
                                            onChange={e => setCategory(e.target.value)}
                                            value={category}
                                            label={"Select a category"}
                                        >
                                            {categories?.map(unit => <option
                                                value={unit.id}>{unit.name}</option>)}
                                        </SelectView>
                                    </Col>
                                    <Col md={12}>
                                        <TextField
                                            type="textarea"
                                            label={"Description"}
                                            onChange={e => setDescription(e.target.value)}
                                            value={description}
                                            rows="3"
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            type={"number"}
                                            label={"Target Amount (EUR)"}
                                            onChange={e => setTargetAmount(e.target.value)}
                                            value={targetAmount}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            type={"number"}
                                            label={"Valuation (EUR)"}
                                            onChange={e => setValuation(e.target.value)}
                                            value={valuation}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            type={"number"}
                                            label={"Total Equity Offered"}
                                            onChange={e => setTotalEquity(e.target.value)}
                                            value={totalEquity}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            type={"number"}
                                            label={"Share Price"}
                                            onChange={e => setSharePrice(e.target.value)}
                                            value={sharePrice}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            type={"number"}
                                            label={"Tax Relief"}
                                            onChange={e => setTaxRelief(e.target.value)}
                                            value={taxRelief}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <TextField
                                            label={"Type of Investment"}
                                            onChange={e => setTypeOfInvestment(e.target.value)}
                                            value={typeOfInvestment}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <DatePicker
                                            label={"Project Deadline"}
                                            onSelected={e => setDeadline(e)}
                                            value={deadline}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <TextField
                                            label={"Video url. e.g YouTube or Vimeo url"}
                                            onChange={e => setVideoUrl(e.target.value)}
                                            value={videoUrl}
                                        />
                                    </Col>

                                    <Col md={12}>
                                        <SelectView
                                            onChange={e => setRiskLevel(e.target.value)}
                                            value={riskLevel}
                                            label={"Select risk level."}
                                        >
                                            <option value={"High Risk"}>High Risk</option>
                                            <option value={"Moderate"}>Moderate</option>
                                            <option value={"Low Risk"}>Low Risk</option>
                                        </SelectView>
                                    </Col>

                                </Row>

                                <hr/>

                                <div className={"float-end justify-content-center d-flex flex-row align-items-center"}>
                                    <div className="form-check form-check-end me-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={e => setCreateAnother(!createAnother)}
                                            checked={createAnother}
                                        />
                                        <label className="form-check-label text-muted">
                                            Create another
                                        </label>
                                    </div>

                                    {(loading[loadingStates.createProject]) ?
                                        <ProgressView/> :
                                        <TamashaButton
                                            extraClassName={"float-end"}
                                            onClick={createProject}>Create</TamashaButton>}
                                </div>

                            </CardBody>
                        </Card>
                    </FormContainer>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewProject
