import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    companies: 'companies',
    createCompany: 'createCompany',
    updateCompany: 'updateCompany',
    deleteCompany: 'deleteCompany',
}

export const useCompanies = () => {
    const [companies, setCompanies] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [photo, setPhoto] = useState('')
    const [coverPhoto, setCoverPhoto] = useState('')
    const [website, setWebsite] = useState('')
    const [overview, setOverview] = useState('')
    const [about, setAbout] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadCompanies = (offset, query, records = 24) => {
        setIsLoading(loadingState(loading, loadingStates.companies, true))

        fetchData(Endpoints.COMPANIES(offset, query, records))
            .then(res => res.json())
            .then(res => {
                setCompanies(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.companies, false)))
    }

    const createCompany = () => {
        setError('')
        setMessage('')

        if (!name || !email || !phoneNumber || !photo || !coverPhoto || !website || !overview || !about) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createCompany, true))

        const data = new FormData()
        data.append("photo", photo)
        data.append("cover", coverPhoto)
        data.append("name", name)
        data.append("email", email)
        data.append("phone_number", phoneNumber)
        data.append("website", website)
        data.append("overview", overview)
        data.append("about", about)

        postData(Endpoints.COMPANY, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setName('')
                setEmail('')
                setPhoneNumber('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    !createAnother && window.location.assign("/dashboard/companies")
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createCompany, false)))
    }

    const updateCompanyDetails = (id) => {
        setError('')
        setMessage('')

        if (!name || !email || !phoneNumber || !website || !overview || !about) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateCompany, true))

        const data = new FormData()
        data.append("id", id)
        data.append("name", name)
        data.append("email", email)
        data.append("phone_number", phoneNumber)
        data.append("website", website)
        data.append("overview", overview)
        data.append("about", about)

        postData(Endpoints.UPDATE_COMPANY_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateCompany, false)))
    }

    const updateCompanyPhoto = (id) => {
        setError('')
        setMessage('')

        if (!photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateCompany, true))

        const data = new FormData()
        data.append("id", id)
        data.append("photo", photo)

        postData(Endpoints.UPDATE_COMPANY_PHOTO, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateCompany, false)))
    }

    const updateCompanyCover = (id) => {
        setError('')
        setMessage('')

        if (!coverPhoto) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateCompany, true))

        const data = new FormData()
        data.append("id", id)
        data.append("cover", coverPhoto)

        postData(Endpoints.UPDATE_COMPANY_COVER, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateCompany, false)))
    }

    return {
        companies,
        loading,
        error,
        loadCompanies,
        createCompany,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        name,
        setName,
        photo,
        setPhoto,
        coverPhoto,
        setCoverPhoto,
        website,
        setWebsite,
        overview,
        setOverview,
        about,
        setAbout,
        updateCompanyPhoto,
        updateCompanyCover,
        updateCompanyDetails
    }

}
