import React, {useEffect, useState} from "react";
import {Col, Modal, Row} from "reactstrap";
import TextField from "./TextField";
import SelectView from "./SelectView";
import ProgressView from "./ProgressView";
import TamashaButton from "./TamashaButton";
import {useImpactRoadmap} from "../hooks/useImpactRoadmap";
import {getYears} from "../util/Util";
import {FormAlert} from "./FormAlert";

export const ImpactRoadmapForm = ({impact, projectID, showModal, setShowModal, onReload}) => {


    const {
        year,
        setYear,
        title,
        setTitle,
        quarter,
        setQuarter,
        description,
        setDescription,
        createImpactRoadmap,
        updateImpactRoadmap,
        loading,
        loadingStates,
        achieved,
        setAchieved,
        message,
        error
    } = useImpactRoadmap()


    useEffect(() => {
        if (!impact) return

        setYear(impact.year)
        setQuarter(impact.quarter)
        setTitle(impact.title)
        setDescription(impact.description)
        setAchieved(impact.achieved ? 1 : 0)
    }, [impact])

    return (
        <div>
            <Modal
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {impact ? "Update entry" : "Add new entry"}
                    </h5>
                    <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <FormAlert message={message} error={error}/>

                    <Row className={"mt-0"}>
                        <Col md={6}>
                            <SelectView
                                onChange={e => setYear(e.target.value)}
                                value={year}
                                label={"Select year"}
                            >
                                {getYears()?.map(y => <option value={y}>{y}</option>)}
                            </SelectView>
                        </Col>
                        <Col md={6}>
                            <SelectView
                                onChange={e => setQuarter(e.target.value)}
                                value={quarter}
                                label={"Select quarter"}
                            >
                                <option>Q1</option>
                                <option>Q2</option>
                                <option>Q3</option>
                                <option>Q4</option>
                            </SelectView>
                        </Col>
                        <Col md={12}>
                            <TextField
                                label={"Title"}
                                onChange={e => setTitle(e.target.value)}
                                value={title}
                            />
                        </Col>
                        <Col md={12}>
                            <TextField
                                type="textarea"
                                label={"Description"}
                                onChange={e => setDescription(e.target.value)}
                                value={description}
                                rows="10"
                            />
                        </Col>
                        <Col md={12}>
                            <SelectView
                                onChange={e => setAchieved(e.target.value)}
                                value={achieved}
                                label={"Select status"}
                            >
                                <option value={1}>Achieved</option>
                                <option value={0}>Not Achieved</option>
                            </SelectView>
                        </Col>
                    </Row>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>

                    {(loading[loadingStates.createImpactRoadmap] || loading[loadingStates.updateImpactRoadmap]) ?
                        <ProgressView/> :
                        <TamashaButton
                            extraClassName={"float-end"}
                            onClick={() => {
                                impact?.id ? updateImpactRoadmap(impact?.id, onReload) : createImpactRoadmap(projectID, onReload)
                            }}>{impact?.id ? "Update" : "Create"}</TamashaButton>}

                </div>

            </Modal>
        </div>

    )
}
