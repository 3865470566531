import React from "react"

import {Card, CardBody, Container} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import TamashaButtonLink from "../../components/TamashaButtonLink";
import CompaniesTable from "../../components/Tables/CompaniesTable";

const Companies = props => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title="Companies"/>
                <Container fluid={true}>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Companies" {...props}/>
                    <Card>
                        <CardBody>

                            <div className="text-end mb-4">
                                <TamashaButtonLink to={"/dashboard/new-company"}><i
                                    className="bx bx-plus font-size-16 align-middle me-2"></i>{" "}Create a company
                                </TamashaButtonLink>
                            </div>

                            <CompaniesTable/>

                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Companies
