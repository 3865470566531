import {Col, Row} from "reactstrap";
import TextField from "./TextField";
import SelectView from "./SelectView";
import React, {useEffect, useState} from "react";

const SearchAndFilter = ({
                             searchPlaceHolder = "Search...",
                             onQuery,
                             onRecords,
                             children,
                         }) => {

    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    useEffect(() => onQuery(query), [query])
    useEffect(() => onRecords(records), [records])

    return (
        <Row>
            <Col md={3}>
                <TextField
                    label={searchPlaceHolder}
                    onChange={e => setQuery(e.target.value)}
                    value={query}
                />
            </Col>
            <Col md={2}>
                <SelectView label={"Records"}
                            onChange={e => setRecords(e.target.value)}
                            value={records}>
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>40</option>
                    <option>50</option>
                    <option>60</option>
                    <option>70</option>
                    <option>80</option>
                    <option>90</option>
                    <option>100</option>
                </SelectView>
            </Col>
            {children}
        </Row>
    )
}

export default SearchAndFilter
