import {clearItem, retrieveItem} from './Storage';
import Endpoints from './Endpoints';
import Strings from "./Strings";

import fetchIntercept from 'fetch-intercept'

const logOutUser = () => {
    clearItem('user')
    clearItem('token')
    clearItem('estate')

    window.location.assign("/login")
}

fetchIntercept.register({
    request(url, config) {
        // Modify the url or config here
        return [url, config]
    },

    requestError(error) {
        console.log(error)
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error)
    },

    response(response) {
        response.status === 302 && logOutUser()
        return response
    },

    responseError(error) {
        // logOutUser()
        // Handle an fetch error
        return Promise.reject(error)
    }
})

export const BASE_URL = Endpoints.SERVER_HOST + Endpoints.API_URL;

let headers = new Headers();

let buildHeaders = () => {
    const item = retrieveItem('token');
    item && headers.set('Authorization', `Bearer ${item}`);
    headers.set('Accept-Encoding', 'br');
    headers.set('Guard-Name', Strings.GUARD_NAME)
    headers.set("timezone", Intl.DateTimeFormat().resolvedOptions().timeZone)
};

export let fetchData = async (endPoint) => {
    const url = BASE_URL + endPoint;
    buildHeaders();
    return fetch(url, {method: 'GET', headers: headers});

};

export let postData = async (endPoint, data) => {
    const url = BASE_URL + endPoint;
    buildHeaders();
    return fetch(url, {method: 'POST', headers: headers, body: data});
};

export let putData = async (endPoint, data) => {
    const url = BASE_URL + endPoint;
    buildHeaders();
    return fetch(url, {method: 'PUT', headers: headers, body: data});
};

export let deleteData = async (endPoint, data) => {
    const url = BASE_URL + endPoint;
    buildHeaders();
    return fetch(url, {method: 'DELETE', headers: headers, body: data});
};

export const getQueryVariable = variable => {
    let query = window.location.search.substring(1);

    let vars = query.split("&");

    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        console.log(pair)
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return "";
};

export const getFileUrl = url => url?.startsWith('http') ? url : Endpoints.SERVER_HOST + Endpoints.FILE_URL + url;
