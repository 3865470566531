import React from "react"
import {Redirect} from "react-router-dom"

import Login from "../pages/Authentication/Login"
import Dashboard from "../pages/Dashboard/index"
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";
import Settings from "../pages/Settings/Settings";
import Companies from "../pages/Companies/Companies";
import NewCompanies from "../pages/Companies/NewCompanies";
import CompanyDetail from "../pages/Companies/CompanyDetail";
import NewProject from "../pages/Projects/NewProject";
import ProjectDetail from "../pages/Projects/ProjectDetail";
import Projects from "../pages/Projects/Projects";
import Accounts from "../pages/Accounts/Accounts";
import AccountDetail from "../pages/Accounts/AccountDetail";
import NewCategory from "../pages/Categories/NewCategory";
import CategoryDetail from "../pages/Categories/CategoryDetail";
import Categories from "../pages/Categories/Categories";
import Quests from "../pages/Quests/Quests";
import NewQuest from "../pages/Quests/NewQuest";
import QuestDetail from "../pages/Quests/QuestDetail";
import NewSkill from "../pages/Skills/NewSkill";
import SkillDetail from "../pages/Skills/SkillDetail";
import NewGroup from "../pages/Groups/NewGroup";
import GroupDetail from "../pages/Groups/GroupDetail";
import Groups from "../pages/Groups/Groups";
import EditGroupDetail from "../pages/Groups/EditGroupDetail";
import EditCategory from "../pages/Categories/EditCategory";
import Transactions from "../pages/Transactions/Transactions";

const userRoutes = [
    {path: "/dashboard/transactions", component: Transactions},

    {path: "/dashboard/skills/:id", component: SkillDetail},
    {path: "/dashboard/new-skill/:id", component: NewSkill},

    {path: "/dashboard/quests", component: Quests},
    {path: "/dashboard/quests/:id", component: QuestDetail},
    {path: "/dashboard/new-quest", component: NewQuest},

    {path: "/dashboard/categories", component: Categories},
    {path: "/dashboard/categories/:id", component: CategoryDetail},
    {path: "/dashboard/category/edit/:id", component: EditCategory},
    {path: "/dashboard/new-category", component: NewCategory},

    {path: "/dashboard/category-groups", component: Groups},
    {path: "/dashboard/category-groups/:id", component: GroupDetail},
    {path: "/dashboard/category-group/edit/:id", component: EditGroupDetail},
    {path: "/dashboard/new-category-group", component: NewGroup},

    {path: "/dashboard/projects", component: Projects},
    {path: "/dashboard/projects/:id", component: ProjectDetail},
    {path: "/dashboard/new-project", component: NewProject},

    {path: "/dashboard/companies", component: Companies},
    {path: "/dashboard/companies/:id", component: CompanyDetail},
    {path: "/dashboard/new-company", component: NewCompanies},

    {path: "/dashboard/settings", component: Settings},

    {path: "/dashboard/accounts", component: Accounts},
    {path: "/dashboard/accounts/:id", component: AccountDetail},

    {path: "/dashboard", component: Dashboard, title: "Overview"},
    {path: "/", exact: true, component: () => <Redirect to="/dashboard"/>},
]

const authRoutes = [
    {path: "/login", component: Login},
    {path: "/forgot-password", component: ForgotPassword},
    {path: "/reset-password", component: ResetPassword},
    {path: "/update-password", component: ChangePassword},

]

export {userRoutes, authRoutes}
