import React, {useEffect, useState} from "react"

import {Card, CardBody, Col, Container, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import TextField from "../../components/TextField";
import {FormAlert} from "../../components/FormAlert";
import {PageLoader} from "../../components/PageLoader";
import {useCompanies} from "../../hooks/useCompanies";
import PortfolioTable from "../../components/Tables/PortfolioTable";
import ProjectsTable from "../../components/Tables/ProjectsTable";
import FilePicker from "../../components/FilePicker";
import DetailsComponent from "../../components/DetailsComponent";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const CompanyDetail = ({match, location, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const {company} = location.state

    const {
        name,
        setName,
        loading,
        error,
        message,
        loadingStates,
        photo,
        setPhoto,
        coverPhoto,
        setCoverPhoto,
        phoneNumber,
        setPhoneNumber,
        website,
        setWebsite,
        email,
        setEmail,
        about,
        setAbout,
        overview,
        setOverview,
        updateCompanyDetails,
        updateCompanyCover,
        updateCompanyPhoto
    } = useCompanies()


    useEffect(() => {
        if (!company) {
            return
        }

        setName(company?.name)
        setEmail(company?.email)
        setPhoneNumber(company?.phone_number)
        setWebsite(company?.website)
        setAbout(company?.about)
        setOverview(company?.overview)

    }, [company])

    return (
        <React.Fragment>
            {company ?
                <div className="page-content">
                    <MetaTitle title={company.name}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={company.name} {...rest}/>

                        <Modal
                            isOpen={showDeleteDialog}
                            toggle={() => {
                                setShowDeleteDialog(!showDeleteDialog)
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Are you sure your want to delete this role?
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Please note that you cannot delete a role if already assigned to a user. In such a
                                    case, please remove this role from affected users before deleting.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {
                                        setShowDeleteDialog(false)

                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                        </Modal>

                        <Card>
                            <CardBody>

                                {/*<div className={"mb-2 mt-2 d-flex justify-content-end"}>*/}
                                {/*    {loading[loadingStates.deleteCompany] ? <ProgressView/> :*/}
                                {/*        <TamashaButton outline extraClassName={"btn-outline-danger mb-2 me-3"}*/}
                                {/*                       onClick={() => setShowDeleteDialog(true)}><i*/}
                                {/*            className="bx bx-trash font-size-16 align-middle me-2"></i>{" "}Delete*/}
                                {/*            company</TamashaButton>}*/}
                                {/*</div>*/}

                                <FormAlert message={message} error={error}/>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 1,
                                            })}
                                            onClick={() => toggle(1)}
                                        >
                                            Projects
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 2,
                                            })}
                                            onClick={() => toggle(2)}
                                        >
                                            Investors
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 3,
                                            })}
                                            onClick={() => toggle(3)}
                                        >
                                            Update Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 4,
                                            })}
                                            onClick={() => toggle(4)}
                                        >
                                            Update Logo
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 5,
                                            })}
                                            onClick={() => toggle(5)}
                                        >
                                            Update Cover Photo
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsComponent
                                            details={[
                                                {title: "Name", des: company.name},
                                                {title: "Email", des: company.email},
                                                {title: "Phone Number", des: company.phone_number},
                                                {title: "Overview", des: company.overview},
                                                {title: "About", des: company.about},
                                                {title: "Website", des: company.website},
                                            ]}
                                        />
                                    </TabPane>
                                    <TabPane tabId={1}>
                                        <ProjectsTable ownerId={company?.id}/>
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <PortfolioTable ownerId={company?.id}/>
                                    </TabPane>
                                    <TabPane tabId={3}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Name"}
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    label={"Contact email"}
                                                    onChange={e => setEmail(e.target.value)}
                                                    value={email}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    label={"Contact phone number"}
                                                    onChange={e => setPhoneNumber(e.target.value)}
                                                    value={phoneNumber}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    type="textarea"
                                                    label={"Brief description"}
                                                    onChange={e => setOverview(e.target.value)}
                                                    value={overview}
                                                    rows="3"
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    type="textarea"
                                                    label={"Long description / Info"}
                                                    onChange={e => setAbout(e.target.value)}
                                                    value={about}
                                                    rows="5"
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Website"}
                                                    onChange={e => setWebsite(e.target.value)}
                                                    value={website}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateCompany] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateCompanyDetails(company?.id)
                                                    }}>Update</TamashaButton>}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId={4}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <FilePicker
                                                    label={"Logo"}
                                                    onChange={e => setPhoto(e.target.files[0])}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateCompany] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateCompanyPhoto(company?.id)
                                                    }}>Update logo</TamashaButton>}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId={5}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <FilePicker
                                                    label={"Cover photo"}
                                                    onChange={e => setCoverPhoto(e.target.files[0])}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateCompany] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateCompanyCover(company?.id)
                                                    }}>Update logo</TamashaButton>}
                                        </div>
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default CompanyDetail
