import React from "react";
import {getFileUrl} from "../../../util/Server";
import DetailsComponent from "../../../components/DetailsComponent";

export const DetailsTab = ({skill}) => {

    return (
        <DetailsComponent
            details={[
                {
                    title: "",
                    des: <img src={getFileUrl(skill?.icon)} height={100}/>
                },
                {
                    title: "Title",
                    des: skill?.title
                },
                {
                    title: "Description",
                    des: skill?.description
                },
                {
                    title: "Reward Points",
                    des: skill.reward_points
                },
                {
                    title: "Background Color",
                    des: skill.background_color
                },
                {
                    title: "Duration",
                    des: skill.duration
                },
            ]}
        />
    )
}
