import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    projects: 'projects',
    findProject: 'findProject',
    createProject: 'createProject',
    updateProject: 'updateProject',
    deleteProject: 'deleteProject',
}

export const useProjects = () => {
    const [projects, setProjects] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [group, setGroup] = useState('')
    const [ownerID, setOwnerID] = useState('')
    const [photo, setPhoto] = useState('')
    const [description, setDescription] = useState('')
    const [totalEquity, setTotalEquity] = useState('')
    const [valuation, setValuation] = useState('')
    const [sharePrice, setSharePrice] = useState('')
    const [targetAmount, setTargetAmount] = useState('')
    const [taxRelief, setTaxRelief] = useState('')
    const [typeOfInvestment, setTypeOfInvestment] = useState('')
    const [deadline, setDeadline] = useState('')
    const [videoUrl, setVideoUrl] = useState('')
    const [region, setRegion] = useState('Europe')
    const [riskLevel, setRiskLevel] = useState('Moderate')

    const [project, setProject] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadProjects = (offset, query, records = 24, project_owner_id = '', categoryId = "", groupId = "") => {
        setIsLoading(loadingState(loading, loadingStates.projects, true))

        fetchData(Endpoints.PROJECTS(offset, query, records, project_owner_id, categoryId, groupId))
            .then(res => res.json())
            .then(res => {
                setProjects(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.projects, false)))
    }

    const findProject = (id) => {
        setIsLoading(loadingState(loading, loadingStates.findProject, true))

        fetchData(Endpoints.PROJECT_WITH_ID(id))
            .then(res => res.json())
            .then(res => {
                setProject(res.data)
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.findProject, false)))
    }

    const createProject = () => {
        setError('')
        setMessage('')

        if (!name || !description || !totalEquity || !photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createProject, true))

        const data = new FormData()
        data.append("file", photo)
        data.append("name", name)
        data.append("description", description)
        data.append("total_equity_offered", totalEquity)
        data.append("valuation", valuation)
        data.append("share_price", sharePrice)
        data.append("target_amount", targetAmount)
        data.append("tax_relief", taxRelief)
        data.append("type_of_investment", typeOfInvestment)
        data.append("category_id", category)
        data.append("group_id", group)
        data.append("owner_id", ownerID)
        data.append("deadline", deadline)
        data.append("video_url", videoUrl)
        data.append("region", region)
        data.append('risk_level', riskLevel)

        postData(Endpoints.PROJECT, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setName('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    !createAnother && window.location.assign("/dashboard/projects")
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createProject, false)))
    }

    const updateProjectDetails = (id) => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateProject, true))

        const data = new FormData()
        data.append("id", id)
        data.append("name", name)
        data.append("description", description)
        data.append("total_equity_offered", totalEquity)
        data.append("valuation", valuation)
        data.append("share_price", sharePrice)
        data.append("target_amount", targetAmount)
        data.append("tax_relief", taxRelief)
        data.append("type_of_investment", typeOfInvestment)
        data.append("category_id", category)
        data.append("owner_id", ownerID)
        data.append("deadline", deadline)
        data.append("video_url", videoUrl)
        data.append("region", region)
        data.append('risk_level', riskLevel)
        data.append("group_id", group)

        postData(Endpoints.UPDATE_PROJECT_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findProject(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateProject, false)))
    }

    const updateProjectStatus = (id, verified) => {
        setError('')
        setMessage('')


        setIsLoading(loadingState(loading, loadingStates.updateProject, true))

        const data = new FormData()
        data.append("id", id)
        data.append("verified", verified)

        postData(Endpoints.UPDATE_PROJECT_STATUS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findProject(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateProject, false)))
    }

    const updateProjectCover = (id) => {
        setError('')
        setMessage('')

        if (!photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateProject, true))

        const data = new FormData()
        data.append("id", id)
        data.append("file", photo)

        postData(Endpoints.UPDATE_PROJECT_PHOTO, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findProject(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateProject, false)))
    }

    return {
        projects,
        loading,
        error,
        loadProjects,
        createProject,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        name,
        setName,
        photo,
        setPhoto,
        updateProjectCover,
        updateProjectDetails,
        findProject,
        updateProjectStatus,
        project,
        deadline,
        setDeadline,
        description,
        setDescription,
        totalEquity,
        setTotalEquity,
        valuation,
        setValuation,
        sharePrice,
        setSharePrice,
        targetAmount,
        setTargetAmount,
        taxRelief,
        setTaxRelief,
        typeOfInvestment,
        setTypeOfInvestment,
        category,
        setCategory,
        ownerID,
        setOwnerID,
        videoUrl,
        setVideoUrl,
        region,
        setRegion,
        riskLevel,
        setRiskLevel,
        group,
        setGroup
    }

}
