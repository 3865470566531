import React, {useCallback, useMemo} from "react";

const CustomPagination = ({pagination = ['Previous', 'Next'], onPrevious, onNext}) => {


    const getLabel = useCallback((label, index) => {
        if (index === 0) {
            return "Previous"
        }

        if (index === pagination?.length - 1) {
            return "Next"
        }

        return label
    }, [pagination])

    return (
        <div
            className="btn-toolbar mt-4"
            role="toolbar"
            aria-label="Toolbar with button groups"
        >
            {/*<div*/}
            {/*    className="btn-group me-2"*/}
            {/*    role="group"*/}
            {/*    aria-label="First group"*/}
            {/*>*/}
            {/*    {pagination?.map((page, index) => <button*/}
            {/*        key={page.label}*/}
            {/*        onClick={() => {*/}
            {/*            index === 0 ? onPrevious() : onNext()*/}
            {/*        }}*/}
            {/*        className={`btn ${page.active ? 'btn btn-secondary' : 'btn btn-outline-secondary'}`}>*/}
            {/*        {getLabel(page.label, index)}*/}
            {/*    </button>)}*/}
            {/*</div>*/}
        </div>
    )
}

export default CustomPagination
