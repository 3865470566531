import React, {useCallback, useEffect} from "react"
import {Container, Row} from "reactstrap"
import MetaTitle from "../../components/MetaTitle"
import CardUser from "./card-user";
import MiniWidget from "./mini-widget";
import {useDashboard} from "../../hooks/useDashboard";

const Dashboard = () => {

    const {loadDashboard, loading, loadingStates, dashboard} = useDashboard()

    const filterReport = useCallback((filterType) => (dashboard?.reports || []).filter(report => report.title?.toLowerCase().includes(filterType.toLowerCase())), [dashboard?.reports])

    const ReportSection = ({title, filterType}) => {
        return (
            <div className={"mb-5"}>
                <h5 className={"mb-4 fw-bold text-uppercase"}>{title}</h5>
                <Row>
                    <MiniWidget reports={filterReport(filterType)}/>
                </Row>
            </div>
        )
    }

    useEffect(() => {
        loadDashboard()
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTitle title={'Dashboard'}/>
                <Container fluid>
                    <CardUser/>

                    <ReportSection title={'Revenue in USD'} filterType={'revenue in usd'}/>
                    <ReportSection title={'Revenue in KES'} filterType={'revenue in kes'}/>
                    <ReportSection title={'Users'} filterType={'user'}/>
                    <ReportSection title={'Events'} filterType={'event'}/>
                    <ReportSection title={'Tickets'} filterType={'tickets'}/>
                    <ReportSection title={'Ticket Sales'} filterType={'sales'}/>
                    <ReportSection title={'Attendees'} filterType={'attendee'}/>
                    <ReportSection title={'Payouts'} filterType={'payouts'}/>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Dashboard
