import React, {useEffect, useState} from "react"

import {Card, CardBody, Col, Container, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTitle from "../../components/MetaTitle"
import classnames from "classnames";
import ProgressView from "../../components/ProgressView";
import TamashaButton from "../../components/TamashaButton";
import TextField from "../../components/TextField";
import {FormAlert} from "../../components/FormAlert";
import {PageLoader} from "../../components/PageLoader";
import {useProjects} from "../../hooks/useProjects";
import {DetailsTab} from "./components/DetailsTab";
import PortfolioTable from "../../components/Tables/PortfolioTable";
import SelectView from "../../components/SelectView";
import DatePicker from "../../components/DatePicker";
import {useCompanies} from "../../hooks/useCompanies";
import {useCategories} from "../../hooks/useCategories";
import FilePicker from "../../components/FilePicker";
import ImpactRoadmapTable from "../../components/Tables/ImpactRoadmapTable";
import {useGroups} from "../../hooks/useGroups";
import TransactionsTable from "../../components/Tables/TransactionsTable";

const styles = {
    tabStyle: {
        cursor: "pointer"
    }
}


const ProjectDetail = ({match, location, ...rest}) => {

    const [activeTab, setActiveTab] = useState(0)
    const toggle = tab => setActiveTab(tab)

    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const {id} = match.params
    const {project: projectFromParams} = location.state

    const {
        name,
        setName,
        loading,
        error,
        message,
        loadingStates,
        setPhoto,
        updateProjectDetails,
        updateProjectCover,
        updateProjectStatus,
        findProject,
        project,
        deadline,
        setDeadline,
        description,
        setDescription,
        totalEquity,
        setTotalEquity,
        valuation,
        setValuation,
        sharePrice,
        setSharePrice,
        targetAmount,
        setTargetAmount,
        taxRelief,
        setTaxRelief,
        typeOfInvestment,
        setTypeOfInvestment,
        category,
        setCategory,
        ownerID,
        setOwnerID,
        videoUrl,
        setVideoUrl,
        region,
        setRegion,
        riskLevel,
        setRiskLevel,
        group,
        setGroup
    } = useProjects()

    const {companies, loadCompanies} = useCompanies()
    const {categories, loadCategories} = useCategories()
    const {loadGroups, groups} = useGroups()

    useEffect(() => {
        loadCompanies(0, '', 0)
        loadGroups(0, '')
    }, [])

    useEffect(() => {
        findProject(id)
    }, [id])

    useEffect(() => {
        if (!project) {
            return
        }

        setName(project?.name)
        setDescription(project?.description)
        setTotalEquity(project?.total_equity_offered)
        setSharePrice(project?.share_price)
        setTaxRelief(project?.tax_relief)
        setTargetAmount(project?.target_amount)
        setTypeOfInvestment(project?.type_of_investment)
        setValuation(project?.valuation)
        setVideoUrl(project?.video_url || "")
        setRegion(project?.region)
        setRiskLevel(project?.risk_level)
        setCategory(project?.category_id)

    }, [project])


    useEffect(() => {
        if (!group) return

        setCategory('')
        loadCategories(group, 0, '')
    }, [group])

    useEffect(() => {
        groups.length > 0 && setGroup(project.group_id)
    }, [groups, project])

    useEffect(() => {
        if (categories.length === 0) {
            setCategory('')
            return
        }

        setCategory(cat => {
            if (cat === '') {
                return categories[0].id
            } else {
                return project?.category_id
            }
        })
    }, [categories, project])

    useEffect(() => {
        companies.length > 0 && setOwnerID(project.project_owner_id)
    }, [companies, project])

    return (
        <React.Fragment>
            {project ?
                <div className="page-content">
                    <MetaTitle title={project.name}/>
                    <Container fluid={true}>
                        <Breadcrumbs title="Dashboard" breadcrumbItem={project.name} {...rest}/>

                        <Modal
                            isOpen={showDeleteDialog}
                            toggle={() => {
                                setShowDeleteDialog(!showDeleteDialog)
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Are you sure your want to delete this role?
                                </h5>
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Please note that you cannot delete a role if already assigned to a user. In such a
                                    case, please remove this role from affected users before deleting.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => setShowDeleteDialog(false)}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {
                                        setShowDeleteDialog(false)

                                    }}
                                >
                                    Confirm
                                </button>
                            </div>
                        </Modal>

                        <Card>
                            <CardBody>

                                <div className={"mb-2 mt-2 d-flex justify-content-end"}>
                                    {loading[loadingStates.updateProject] ? <ProgressView/> :
                                        <TamashaButton
                                            extraClassName={`${project?.verified ? "btn-danger" : "btn-success"} mb-2 me-3`}
                                            onClick={() => {
                                                updateProjectStatus(project?.id, project.verified ? 0 : 1)
                                            }}>
                                            {project.verified ? "Unverify project" : "Verify project"}
                                        </TamashaButton>}
                                </div>

                                <FormAlert message={message} error={error}/>

                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 0,
                                            })}
                                            onClick={() => toggle(0)}
                                        >
                                            Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 1,
                                            })}
                                            onClick={() => toggle(1)}
                                        >
                                            Investors
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 7,
                                            })}
                                            onClick={() => toggle(7)}
                                        >
                                            Transactions
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 2,
                                            })}
                                            onClick={() => toggle(2)}
                                        >
                                            Update Details
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 5,
                                            })}
                                            onClick={() => toggle(5)}
                                        >
                                            Update Deadline
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 4,
                                            })}
                                            onClick={() => toggle(4)}
                                        >
                                            Update Cover Photo
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={styles.tabStyle}
                                            className={classnames({
                                                active: activeTab === 6,
                                            })}
                                            onClick={() => toggle(6)}
                                        >
                                            Impact Roadmap
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={activeTab} className="p-3 text-muted">
                                    <TabPane tabId={0}>
                                        <DetailsTab project={project}/>
                                    </TabPane>
                                    <TabPane tabId={1}>
                                        <PortfolioTable projectID={project?.id}/>
                                    </TabPane>

                                    <TabPane tabId={2}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <SelectView
                                                    onChange={e => setRegion(e.target.value)}
                                                    value={region}
                                                    label={"Select region."}
                                                >
                                                    <option value={"Europe"}>Europe</option>
                                                    <option value={"Malaysia"}>Malaysia</option>
                                                </SelectView>
                                                <p>Europe - Projects in this region will be paid by Stripe method with
                                                    an INVEST
                                                    button</p>
                                                <p>Malaysia - Projects in this region will be paid by Curlec method with
                                                    a
                                                    DONATE button</p>
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Name"}
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <SelectView
                                                    onChange={e => setOwnerID(e.target.value)}
                                                    value={ownerID}
                                                    label={"Select company"}
                                                >
                                                    {companies?.map(unit => <option
                                                        value={unit.id}>{unit.name}</option>)}
                                                </SelectView>
                                            </Col>

                                            <Col md={6}>
                                                <SelectView
                                                    onChange={e => setGroup(e.target.value)}
                                                    value={group}
                                                    label={"Select category group"}
                                                >
                                                    {groups?.map(unit => <option
                                                        value={unit.id}>{unit.name}</option>)}
                                                </SelectView>
                                            </Col>
                                            <Col md={6}>
                                                <SelectView
                                                    onChange={e => setCategory(e.target.value)}
                                                    value={category}
                                                    label={"Select a category"}
                                                >
                                                    {categories?.map(unit => <option
                                                        value={unit.id}>{unit.name}</option>)}
                                                </SelectView>
                                            </Col>

                                            <Col md={12}>
                                                <TextField
                                                    type="textarea"
                                                    label={"Description"}
                                                    onChange={e => setDescription(e.target.value)}
                                                    value={description}
                                                    rows="10"
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    type={"number"}
                                                    label={"Target Amount (EUR)"}
                                                    onChange={e => setTargetAmount(e.target.value)}
                                                    value={targetAmount}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    type={"number"}
                                                    label={"Valuation (EUR)"}
                                                    onChange={e => setValuation(e.target.value)}
                                                    value={valuation}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    type={"number"}
                                                    label={"Total Equity Offered"}
                                                    onChange={e => setTotalEquity(e.target.value)}
                                                    value={totalEquity}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    type={"number"}
                                                    label={"Share Price"}
                                                    onChange={e => setSharePrice(e.target.value)}
                                                    value={sharePrice}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    type={"number"}
                                                    label={"Tax Relief"}
                                                    onChange={e => setTaxRelief(e.target.value)}
                                                    value={taxRelief}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <TextField
                                                    label={"Type of Investment"}
                                                    onChange={e => setTypeOfInvestment(e.target.value)}
                                                    value={typeOfInvestment}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <TextField
                                                    label={"Video url. e.g YouTube or Vimeo url"}
                                                    onChange={e => setVideoUrl(e.target.value)}
                                                    value={videoUrl}
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <SelectView
                                                    onChange={e => setRiskLevel(e.target.value)}
                                                    value={riskLevel}
                                                    label={"Select risk level."}
                                                >
                                                    <option value={"High Risk"}>High Risk</option>
                                                    <option value={"Moderate"}>Moderate</option>
                                                    <option value={"Low Risk"}>Low Risk</option>
                                                </SelectView>
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateProject] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateProjectDetails(project?.id)
                                                    }}>Update</TamashaButton>}
                                        </div>
                                    </TabPane>

                                    <TabPane tabId={5}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <DatePicker
                                                    label={"Project Deadline"}
                                                    onSelected={e => setDeadline(e)}
                                                    value={deadline}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateProject] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateProjectDetails(project?.id)
                                                    }}>Update</TamashaButton>}
                                        </div>
                                    </TabPane>

                                    <TabPane tabId={4}>
                                        <Row className={"mt-0"}>
                                            <Col md={12}>
                                                <FilePicker
                                                    label={"Cover photo"}
                                                    onChange={e => setPhoto(e.target.files[0])}
                                                />
                                            </Col>
                                        </Row>

                                        <div className={"float-end justify-content-end"}>
                                            {loading[loadingStates.updateProject] ? <ProgressView/> :
                                                <TamashaButton
                                                    extraClassName={"float-end"}
                                                    onClick={() => {
                                                        updateProjectCover(project?.id)
                                                    }}>Update Cover</TamashaButton>}
                                        </div>
                                    </TabPane>

                                    <TabPane tabId={6}>
                                        <ImpactRoadmapTable projectID={project?.id}/>
                                    </TabPane>

                                     <TabPane tabId={7}>
                                        <TransactionsTable projectID={project?.id}/>
                                    </TabPane>
                                </TabContent>

                            </CardBody>
                        </Card>

                    </Container>
                </div>
                : <PageLoader/>
            }

        </React.Fragment>
    )
}

export default ProjectDetail
