import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import CustomPagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import {useImpactRoadmap} from "../../hooks/useImpactRoadmap";
import {getYears} from "../../util/Util";
import {ImpactRoadmapForm} from "../ImpactRoadmapForm";
import {Col, Row} from "reactstrap";
import SelectView from "../SelectView";
import TamashaButton from "../TamashaButton";

const ImpactRoadmapTable = ({projectID = ""}) => {

    const {loadImpactRoadmap, impactRoadmap, loading, loadingStates, deleteImpactRoadmap} = useImpactRoadmap()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    const [year, setYear] = useState(new Date().getFullYear())
    const [quarter, setQuarter] = useState('Q1')

    const [activeItem, setItem] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const debouncedQuery = useDebounce(query, 500)

    const fetchData = () => {
        loadImpactRoadmap(projectID, year, quarter)
    }

    useEffect(() => {
        fetchData()
    }, [projectID, year, quarter])

    useEffect(() => {
        if (!showModal) {
            setItem(null)
        }
    }, [showModal])

    return <>

        <ImpactRoadmapForm impact={activeItem}
                           projectID={projectID}
                           showModal={showModal}
                           setShowModal={setShowModal}
                           onReload={() => {
                               fetchData()
                           }}/>

        <div className="text-end mb-4">
            <TamashaButton onClick={e => setShowModal(true)}>
                Add new entry
            </TamashaButton>
        </div>

        <hr/>
        <Row className={"mt-0"}>
            <Col md={6}>
                <SelectView
                    onChange={e => setYear(e.target.value)}
                    value={year}
                    label={"Select year"}
                >
                    {getYears()?.map(y => <option value={y}>{y}</option>)}
                </SelectView>
            </Col>
            <Col md={6}>
                <SelectView
                    onChange={e => setQuarter(e.target.value)}
                    value={quarter}
                    label={"Select quarter"}
                >
                    <option>Q1</option>
                    <option>Q2</option>
                    <option>Q3</option>
                    <option>Q4</option>
                </SelectView>
            </Col>
        </Row>


        {(loading[loadingStates.impactRoadmap] || loading[loadingStates.deleteImpactRoadmap]) &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.impactRoadmap] && impactRoadmap.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {(impactRoadmap.length > 0 && !loading[loadingStates.deleteImpactRoadmap]) &&
        <div className="mt-3">
            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Year</Th>
                                <Th data-priority="1">Quarter</Th>
                                <Th data-priority="1">Title</Th>
                                <Th data-priority="1">Description</Th>
                                <Th data-priority="1">Status</Th>
                                <Th data-priority="1"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {impactRoadmap.map((port, b) =>
                                <Tr key={port.id}>
                                    <Td>
                                        {port.year}
                                    </Td>
                                    <Td>
                                        {port.quarter}
                                    </Td>
                                    <Td>{port.title}</Td>
                                    <Td>{port.description}</Td>
                                    <Td>{port.achieved ? "ACHIEVED" : "NOT ACHIEVED"}</Td>
                                    <Td>
                                        <TamashaButton extraClassName={'btn-sm btn-rounded'} onClick={e => {
                                            setItem(port)
                                            setShowModal(true)
                                        }}>
                                            Edit
                                        </TamashaButton>
                                        <TamashaButton extraClassName={'btn-sm btn-danger btn-rounded ms-2'}
                                                       onClick={e => {
                                                           deleteImpactRoadmap(port.id, () => {
                                                               fetchData()
                                                           })
                                                       }}
                                        >
                                            Delete
                                        </TamashaButton>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    {!debouncedQuery &&
                    <CustomPagination
                        onPrevious={() => setPage(impactRoadmap.length - records)}
                        onNext={() => setPage(impactRoadmap.length)}
                    />
                    }

                </div>
            </div>
        </div>
        }
    </>

}

export default ImpactRoadmapTable
