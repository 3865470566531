import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";
import {ERROR, Notify, SUCCESS} from "../util/Notify";

const loadingStates = {
    impactRoadmap: 'impactRoadmap',
    createImpactRoadmap: 'createImpactRoadmap',
    updateImpactRoadmap: 'updateImpactRoadmap',
    deleteImpactRoadmap: 'deleteImpactRoadmap',
}

export const useImpactRoadmap = () => {
    const [impactRoadmap, setImpactRoadmap] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [year, setYear] = useState(new Date().getFullYear())
    const [quarter, setQuarter] = useState('Q1')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [achieved, setAchieved] = useState(0)


    const loadImpactRoadmap = (projectID, year, quarter) => {
        setIsLoading(loadingState(loading, loadingStates.impactRoadmap, true))

        fetchData(Endpoints.LIST_ROADMAP(projectID, year, quarter))
            .then(res => res.json())
            .then(res => {
                setImpactRoadmap(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.impactRoadmap, false)))
    }

    const createImpactRoadmap = (projectID, cb) => {
        setError('')
        setMessage('')

        if (!year || !quarter || !title || !description) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createImpactRoadmap, true))

        const data = new FormData()
        data.append("year", year)
        data.append("quarter", quarter)
        data.append("title", title)
        data.append("description", description)
        data.append("project_id", projectID)
        data.append("achieved", achieved)

        postData(Endpoints.CREATE_ROADMAP, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                setTitle('')
                setDescription('')

                cb?.()

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createImpactRoadmap, false)))
    }

    const updateImpactRoadmap = (id, cb) => {
        setError('')
        setMessage('')

        if (!title || !description) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createImpactRoadmap, true))

        const data = new FormData()
        data.append("title", title)
        data.append("description", description)
        data.append("id", id)
        data.append("achieved", achieved)

        postData(Endpoints.UPDATE_ROADMAP, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                cb?.()

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createProject, false)))
    }

    const deleteImpactRoadmap = (id, cb) => {
        setError('')
        setMessage('')

        setIsLoading(loadingState(loading, loadingStates.deleteImpactRoadmap, true))

        const data = new FormData()
        data.append("id", id)

        postData(Endpoints.DELETE_ROADMAP, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    Notify(res.errors?.[0] || res.message, ERROR)
                    return;
                }

                Notify(res.data, SUCCESS)

                cb?.()

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.deleteImpactRoadmap, false)))
    }

    return {
        impactRoadmap,
        loading,
        error,
        loadImpactRoadmap,
        message,
        setMessage,
        loadingStates,
        createImpactRoadmap,
        deleteImpactRoadmap,
        updateImpactRoadmap,
        year,
        setYear,
        quarter,
        setQuarter,
        title,
        setTitle,
        description,
        setDescription,
        achieved,
        setAchieved
    }

}
