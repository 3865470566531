import {useState} from "react";
import {fetchData, postData} from "../util/Server";
import Endpoints from "../util/Endpoints";
import Strings from "../util/Strings";
import {loadingState} from "../util/Util";

const loadingStates = {
    quests: 'quests',
    findQuest: 'findQuest',
    createQuest: 'createQuest',
    updateQuest: 'updateQuest',
    deleteQuest: 'deleteQuest',
}

export const useQuests = () => {
    const [quests, setQuests] = useState([])
    const [loading, setIsLoading] = useState({})
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')

    const [name, setName] = useState('')
    const [photo, setPhoto] = useState('')
    const [description, setDescription] = useState('')
    const [hasDeadline, setHasDeadline] = useState(0)
    const [isAppManaged, setIsAppManaged] = useState(0)
    const [deadline, setDeadline] = useState('')
    const [rewardPoints, setRewardPoints] = useState(0)
    const [appID, setAppID] = useState('')

    const [quest, setQuest] = useState('')

    const [createAnother, setCreateAnother] = useState(false)

    const loadQuests = (offset, query, records = 24, quest_owner_id = '', categoryId = "") => {
        setIsLoading(loadingState(loading, loadingStates.quests, true))

        fetchData(Endpoints.QUESTS(offset, query, records, quest_owner_id, categoryId))
            .then(res => res.json())
            .then(res => {
                setQuests(res.data || [])
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.quests, false)))
    }

    const findQuest = (id) => {
        setIsLoading(loadingState(loading, loadingStates.findQuest, true))

        fetchData(Endpoints.QUEST_WITH_ID(id))
            .then(res => res.json())
            .then(res => {
                setQuest(res.data)
            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.findQuest, false)))
    }

    const createQuest = () => {
        setError('')
        setMessage('')

        if (!name || !description || !photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.createQuest, true))

        const data = new FormData()
        data.append("file", photo)
        data.append("title", name)
        data.append("description", description)
        data.append("has_deadline", parseInt(hasDeadline) === 1)
        data.append("is_app_managed", parseInt(isAppManaged) === 1)
        data.append("reward_points", rewardPoints)
        data.append("deadline", deadline)
        data.append("app_id", appID)

        postData(Endpoints.QUEST, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)
                setName('')

                setTimeout(() => {
                    setMessage('')
                    setError('')
                    !createAnother && window.location.assign("/dashboard/quests")
                }, 1500)


            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.createQuest, false)))
    }

    const updateQuestDetails = (id) => {
        setError('')
        setMessage('')

        if (!name) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateQuest, true))

        const data = new FormData()
        data.append("id", id)
        data.append("title", name)
        data.append("description", description)
        data.append("has_deadline", parseInt(hasDeadline) === 1)
        data.append("is_app_managed", parseInt(isAppManaged) === 1)
        data.append("reward_points", rewardPoints)
        data.append("deadline", deadline)
        data.append("app_id", appID)

        postData(Endpoints.UPDATE_QUEST_DETAILS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findQuest(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateQuest, false)))
    }

    const updateQuestStatus = (id, verified) => {
        setError('')
        setMessage('')


        setIsLoading(loadingState(loading, loadingStates.updateQuest, true))

        const data = new FormData()
        data.append("id", id)
        data.append("verified", verified)

        postData(Endpoints.UPDATE_QUEST_STATUS, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findQuest(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateQuest, false)))
    }

    const updateQuestCover = (id) => {
        setError('')
        setMessage('')

        if (!photo) {
            setError('All fields are required')
            return
        }

        setIsLoading(loadingState(loading, loadingStates.updateQuest, true))

        const data = new FormData()
        data.append("id", id)
        data.append("file", photo)

        postData(Endpoints.UPDATE_QUEST_PHOTO, data)
            .then(res => res.json())
            .then(res => {
                if (!res.success) {
                    setError(res.errors?.[0] || res.message)
                    return;
                }

                setMessage(res.data)

                findQuest(id)

                setTimeout(() => {
                    setMessage('')
                    setError('')
                }, 1500)

            }).catch(e => {
            setError(Strings.CONNECTION_ERROR_MESSAGE)
        })
            .finally(() => setIsLoading(loadingState(loading, loadingStates.updateQuest, false)))
    }

    return {
        quests,
        loading,
        error,
        loadQuests,
        createQuest,
        createAnother,
        setCreateAnother,
        message,
        setMessage,
        loadingStates,
        name,
        setName,
        photo,
        setPhoto,
        updateQuestCover,
        updateQuestDetails,
        findQuest,
        updateQuestStatus,
        quest,
        deadline,
        setDeadline,
        description,
        setDescription,
        hasDeadline,
        setHasDeadline,
        isAppManaged,
        setIsAppManaged,
        rewardPoints,
        setRewardPoints,
        appID,
        setAppID
    }

}
