import React, {useEffect, useState} from "react"
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import ProgressView from "../ProgressView"
import TamashaButtonLink from "../TamashaButtonLink";
import CustomPagination from "../Pagination";
import useDebounce from "../../hooks/useDebounce";
import SearchAndFilter from "../SearchAndFilter";
import {useTransactions} from "../../hooks/useTransactions";
import {getFormarttedNumber, getFormarttedNumberND} from "../../util/Util";
import {Link} from "react-router-dom";

const TransactionsTable = ({projectId = ""}) => {

    const {loadTransactions, transactions, loading, loadingStates} = useTransactions()
    const [page, setPage] = useState(1)
    const [query, setQuery] = useState('')
    const [records, setRecords] = useState(10)

    const debouncedQuery = useDebounce(query, 500)

    useEffect(() => {
        loadTransactions(projectId, debouncedQuery ? 0 : transactions.length, debouncedQuery, records)
    }, [page, debouncedQuery, records, projectId])

    useEffect(() => {
        setPage(0)
    }, [records])

    return <>

        <SearchAndFilter onQuery={query => setQuery(query)} onRecords={records => setRecords(records)}/>

        {loading[loadingStates.transactions] &&
        <div className={"p-2 text-center"}>
            <ProgressView/>
        </div>
        }

        {!loading[loadingStates.transactions] && transactions.length === 0 &&
        <div className={"p-2 text-center"}>
            <p className="mb-2 text-muted">No data available</p>
        </div>
        }

        {transactions.length > 0 &&
        <div className="mt-3">

            <div className="table-rep-plugin">
                <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                >
                    <Table className="table table-striped table-bordered">
                        <Thead>
                            <Tr>
                                <Th data-priority="1">Project Name</Th>
                                <Th data-priority="1">User</Th>
                                <Th data-priority="1">Amount</Th>
                                <Th data-priority="1">Status</Th>
                                <Th data-priority="1">Processor</Th>
                                <Th data-priority="4"></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {transactions.map((transaction, b) =>
                                <Tr key={b.toString()}>
                                    <Td>
                                        <Link to={{
                                            pathname: "/dashboard/projects/" + transaction.project_id,
                                            state: {project: transaction.project_details}
                                        }}>
                                            {transaction.project_details?.name}
                                        </Link>
                                    </Td>
                                    <Td>
                                        <Link to={{
                                            pathname: "/dashboard/projects/" + transaction.user_id,
                                            state: {account: transaction.user_details}
                                        }}>
                                            {transaction.user_details?.first_name} {transaction.user_details?.last_name}
                                        </Link>
                                    </Td>
                                    <Td>{getFormarttedNumber(transaction.amount)}</Td>
                                    <Td>{transaction.status.toUpperCase()}</Td>
                                    <Td>
                                        {transaction.processor.toUpperCase()}
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>

                    {!debouncedQuery &&
                    <CustomPagination
                        onPrevious={() => setPage(transactions.length - records)}
                        onNext={() => setPage(transactions.length)}
                    />
                    }

                </div>
            </div>
        </div>
        }
    </>

}

export default TransactionsTable
