import PropTypes from 'prop-types'
import React, {Component} from "react"
import {Col, Card, CardBody} from "reactstrap"
import {formatNumber, formatNumberNoRound, getFormarttedNumber} from "../../util/Util";

const CompareLine = ({value, percentage}) => {

    return (
        <>
            {value !== 0 &&
            <div className="text-muted mt-1">
                <span className="font-size-12">{`${getFormarttedNumber(value)} (${getFormarttedNumber(percentage)}%) change from previous.`}</span>
            </div>
            }
        </>
    )
}

class MiniWidget extends Component {


    render() {
        return (
            <React.Fragment>
                {this.props.reports.map((report, key) => (
                    <Col sm="4" key={key}>
                        <Card>
                            <CardBody>
                                {report.value === null ?
                                    <>
                                        <div className="d-flex align-items-center mb-3">
                                            <h5 className="font-size-14 mb-0">{report.title}</h5>
                                        </div>
                                        <div className="mt-4">
                                            <h6 className={"text-muted"}>
                                                Gross Revenue
                                            </h6>
                                            <h4>{getFormarttedNumber(report.revenue.gross_revenue)}</h4>
                                            <CompareLine value={report.revenue.gross_revenue_change_value}
                                                         percentage={report.revenue.gross_revenue_change_perc}/>
                                            <hr/>
                                        </div>
                                        <div className="mt-4">
                                            <h6 className={"text-muted"}>
                                                Total Commission
                                            </h6>
                                            <h4>{getFormarttedNumber(report.revenue.total_commission)}</h4>
                                            <CompareLine value={report.revenue.total_commission_change_value}
                                                         percentage={report.revenue.total_commission_change_perc}/>
                                            <hr/>
                                        </div>

                                         <div className="mt-4">
                                            <h6 className={"text-muted"}>
                                                Net Commission
                                            </h6>
                                            <h4>{getFormarttedNumber(report.revenue.net_commission)}</h4>
                                            <CompareLine value={report.revenue.net_commission_change_value}
                                                         percentage={report.revenue.net_commission_change_perc}/>
                                        </div>

                                    </>
                                    :
                                    <>
                                        <div className="d-flex align-items-center mb-3">
                                            <h5 className="font-size-14 mb-0">{report.title}</h5>
                                        </div>
                                        <div className="text-muted mt-3">
                                            <h4>
                                                {report.value || 0}
                                            </h4>
                                        </div>
                                        <CompareLine value={report.change_value}
                                                     percentage={report.change_percentage}/>
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </React.Fragment>
        )
    }
}

MiniWidget.propTypes = {
    reports: PropTypes.array
}

export default MiniWidget
